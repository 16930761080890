import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootCard: {
      margin: "auto",
      padding: 0,
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1) !important",
      borderRadius: 8,
      backgroundColor: "#ffffff",
    },
    cardContentWrapper: { display: "flex", alignItems: "center" },
    menuWalletVoucherWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "50%",
      cursor: "pointer",
      padding: "16px !important",
    },
    walletWrapper: {
      cursor: "pointer",
      display: "flex",
      alignItems: "baseline",
      justifyContent: "space-between",
    },
    walletIcon: {
      marginRight: 10,
    },
    walletAmount: {
      fontWeight: 700,
      fontSize: 14,
      marginBottom: 1,
    },
    totalPoint: {
      color: "#25282B",
      fontSize: 12,
    },
    myPoints: {
      fontSize: 12,
      marginBottom: 1,
      color: "#595959",
    },
    voucher: {
      color: "#25282B",
      fontWeight: 700,
      fontSize: 14,
      marginBottom: 1,
    },
    totalVoucher: {
      color: "#A8ABAD",
      fontSize: 12,
    },
    membershipWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 15px 15px 15px",
    },
    groupWrapper: {
      width: "100%",
      maxWidth: "340px",
    },
    groupTypography: {
      fontSize: 14,
      fontWeight: 500,
      color: "#52575C",
      marginBottom: 4,
    },
    expTypography: {
      fontSize: 12,
      color: "#A8ABAD",
      marginLeft: 6,
    },
    chevronRight: {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
    progressRoot: {
      backgroundColor: "#E8E8E8 !important",
      height: "5px !important",
      borderRadius: "2px",
    },
    becomeMemberCard: {
      padding: "10px 16px !important",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    becomeMemberTitle: {
      fontSize: 14,
      fontWeight: 600,
    },
    becomeMemberSubtitle: {
      color: "#a0a4a8",
      fontSize: 12,
    },
  })
);

export default useStyles;
