const HelpIconActive = () => {
  return (
    <svg
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6664 0H2.33305C1.11734 0 0.142578 0.985714 0.142578 2.19048V17.5238C0.142578 18.7286 1.11734 19.7143 2.33305 19.7143H6.71401L9.99972 23L13.2854 19.7143H17.6664C18.8711 19.7143 19.8569 18.7286 19.8569 17.5238V2.19048C19.8569 0.985714 18.8711 0 17.6664 0ZM11.0954 16.4288C11.0954 17.0338 10.6049 17.5242 9.99993 17.5242C9.39493 17.5242 8.90449 17.0338 8.90449 16.4288C8.90449 15.8238 9.39493 15.3333 9.99993 15.3333C10.6049 15.3333 11.0954 15.8238 11.0954 16.4288ZM13.3621 9.03571L12.3764 10.0433C11.7903 10.6375 11.3676 11.1531 11.1891 12.0531C11.0715 12.6464 10.6046 13.1429 9.99973 13.1429H9.45211C9.14966 13.1429 8.90449 12.8977 8.90449 12.5952C8.90449 11.3905 9.39734 10.2952 10.1859 9.49571L11.544 8.11571C11.9492 7.72143 12.1902 7.17381 12.1902 6.57143C12.1902 5.36667 11.2045 4.38095 9.99973 4.38095C9.17462 4.38095 8.45225 4.8433 8.07892 5.52172C7.78729 6.05166 7.31889 6.57143 6.71401 6.57143C6.10913 6.57143 5.60472 6.07347 5.75382 5.48725C6.236 3.59159 7.95349 2.19048 9.99973 2.19048C12.4202 2.19048 14.3807 4.15095 14.3807 6.57143C14.3807 7.53524 13.9864 8.41143 13.3621 9.03571Z"
        fill="#25282B"
      />
    </svg>
  );
};

export default HelpIconActive;
