const OrderIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.49995 0H17.3077C18.0221 0 18.7066 0.269381 19.2114 0.748926C19.7162 1.22847 19.9999 1.87883 19.9999 2.55749V19.3643C20.0047 19.6228 19.8662 19.8648 19.6345 20L3.84618 19.9854C3.61925 19.852 3.48078 19.6173 3.48078 19.3643V10.8841L1.13076 11.9802C0.892313 12.0925 0.610581 12.0797 0.384618 11.9473C0.150002 11.8185 0.00480766 11.5829 0 11.3262V2.37481C0.00196924 1.06413 1.12013 0.00182671 2.49995 0ZM1.53835 10.1426L3.46144 9.22917L3.46141 2.37486C3.46141 1.87067 3.03064 1.46146 2.49988 1.46146C1.96912 1.46146 1.53835 1.87067 1.53835 2.37486V10.1426ZM5.00006 18.1805H18.4617L18.4616 2.5575C18.4616 1.95192 17.9453 1.46141 17.3078 1.46141H4.80775C4.93467 1.75094 5.00006 2.06152 5.00006 2.37481V18.1805ZM15.6 5.00336C16.0228 5.00465 16.3655 5.33016 16.3669 5.7319C16.3655 6.13362 16.0229 6.45916 15.6 6.46046L8.07007 6.46048C7.85658 6.47597 7.64581 6.40622 7.48807 6.268C7.33101 6.13043 7.24126 5.93538 7.24194 5.73193C7.24127 5.52847 7.33101 5.33342 7.48807 5.19585C7.6458 5.05762 7.85657 4.98787 8.07007 5.00337L15.6 5.00336ZM16.3669 9.62912C16.3655 9.22738 16.0228 8.90186 15.6 8.90057L8.07007 8.90059C7.85657 8.88509 7.6458 8.95484 7.48807 9.09307C7.33101 9.23064 7.24127 9.42569 7.24194 9.62914C7.24126 9.8326 7.33101 10.0276 7.48807 10.1652C7.64581 10.3034 7.85658 10.3732 8.07007 10.3577L15.6 10.3577C16.0229 10.3564 16.3655 10.0308 16.3669 9.62912ZM15.6 12.7978C16.0228 12.7991 16.3655 13.1246 16.3669 13.5263C16.3655 13.9281 16.0229 14.2536 15.6 14.2549L8.07007 14.2549C7.85658 14.2704 7.64581 14.2007 7.48807 14.0624C7.33101 13.9249 7.24126 13.7298 7.24194 13.5264C7.24127 13.3229 7.33101 13.1279 7.48807 12.9903C7.6458 12.8521 7.85657 12.7823 8.07007 12.7978L15.6 12.7978Z"
        fill="#A0A4A8"
      />
    </svg>
  );
};

export default OrderIcon;
