import { axiosInstance } from "../config/axios";
import axios from "axios";
import { GuestPayload, GuestResponseDto } from "../libs/guest";
import { ListBannerDto } from "../libs/banner";

export const getListGuestProfile = async (page?: number, perPage?: number) => {
  const response: GuestResponseDto = await axiosInstance.get(
    `/guest/guest-profiles?page=${page || 1}&per_page=${perPage || 100}`
  );
  return response;
};

export const deleteGuestProfile = async (profile_id: string) => {
  const response = await axiosInstance.delete(
    `/guest/guest-profiles/${profile_id}`
  );
  return response;
};

export const getTenantProfile = async () => {
  const response = await axiosInstance.get("/guest/tenant/profile");
  return response;
};

export const uploadIdCardImage = async (data) => {
  const response = await axiosInstance.post(`/guest/profile/id-card`, data);
  return response;
};

export const addGuestProfile = async (data: GuestPayload) => {
  const response = await axiosInstance.post(`/guest/guest-profiles`, data);
  return response;
};

export const updateGuestProfile = async (id: string, data: GuestPayload) => {
  const response = await axiosInstance.put(`/guest/guest-profiles/${id}`, data);
  return response;
};

export const getGuestProfile = async (id: string) => {
  const response = await axiosInstance.get(`/guest/guest-profiles/${id}`);
  return response;
};

export const getListAddresses = async () => {
  const response = await axiosInstance.get(`/guest/addresses`);
  return response;
};

export const getRoomDetail = async (
  page?: number,
  perPage?: number,
  status: string
) => {
  const response = await axiosInstance.get(
    `/guest/orders?page=${page || 1}&per_page=${
      perPage || 100
    }&order_type=["ROOM"]&status=${status || ""}`
  );
  return response;
};

export const getLocationByOpenStreetMapReverse = async (lat, lon) => {
  const response = await axios
    .get(
      `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
    )
    .then((res) => res.data);
  return response;
};

export const getLocationByOpenStreetMapStreet = async (street) => {
  const response = await axios
    .get(
      `https://nominatim.openstreetmap.org/search?street=${street}&country=id&format=json`
    )
    .then((res) => res.data);
  return response;
};

export const getAddressById = async (id: string) => {
  const response = await axiosInstance.get(`/guest/addresses/${id}`);
  return response;
};

export const createAddress = async (data) => {
  const response = await axiosInstance.post(`/guest/addresses`, data);
  return response;
};

export const updateAddress = async (id, data) => {
  const response = await axiosInstance.put(`/guest/addresses/${id}`, data);
  return response;
};

export const deleteAddress = async (id: string) => {
  const response = await axiosInstance.delete(`/guest/addresses/${id}`);
  return response;
};
