import { axiosInstance } from "../config/axios";

export const getGuestTotalBalances = async () => {
  const response = await axiosInstance.get(`/guest/wallets`);
  return response;
};

export const cancelWalletOrder = async (id: any) => {
  const response = await axiosInstance.put(`/guest/wallets/orders/${id}/status-cancel`)
  return response
}
