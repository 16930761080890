import { axiosInstance } from "../config/axios";
import { GuestMembershipByGroupIdDto } from "../libs/membership";

export const getGuestMembershipByGroupId = async (
  groupId: string
): Promise<GuestMembershipByGroupIdDto> => {
  const response: GuestMembershipByGroupIdDto = await axiosInstance.get(
    `/guest/memberships/${groupId}/detail`
  );
  return response;
};
