import {
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { TFunction } from "next-i18next";
import Link from "next/link";
import React from "react";
import { i18n, Router } from "../../i18n";
import VoucherIcon from "../../public/img/VoucherIcon";
import numberSeparator from "../../src/utils/numberSeparator";
import useStyles from "../../styles/components/CardMembership";
import { GuestMembershipByGroupIdDto } from "../libs/membership";
import { TenantProfileDto } from "../libs/tenant";

type GetLevelDifferenceFunction = (
  data: GuestMembershipByGroupIdDto["data"]
) => string;

type GetLevelProgressFunction = (
  data: GuestMembershipByGroupIdDto["data"]
) => number;

type CardMembershipDto = {
  readonly t: TFunction;
  isLogin: boolean;
  wallet: number;
  points: number;
  totalVoucher: number;
  membershipData?: GuestMembershipByGroupIdDto["data"];
  tenantProfile: TenantProfileDto;
};

const CardMembership = (props: CardMembershipDto) => {
  const {
    isLogin,
    wallet,
    points,
    totalVoucher,
    membershipData,
    tenantProfile,
    t,
  } = props;
  const classes = useStyles();

  const getLevelDifference: GetLevelDifferenceFunction = (data) => {
    const levelToUp: number =
      data.group.levels[data.level.level + 1].amountToLevelUp;
    const currentLevel: number = data.levelProgress;
    const levelDifference: number = levelToUp - currentLevel;
    return numberSeparator(levelDifference).split(".").join(",");
  };

  const getLevelProgress: GetLevelProgressFunction = (data) => {
    const levelToUp: number =
      data.group.levels[data.level.level + 1].amountToLevelUp;
    const currentLevel: number = data.levelProgress;
    return (currentLevel / levelToUp) * 100;
  };

  return (
    <div>
      {isLogin && tenantProfile.membershipConfig ? (
        <Card className={classes.rootCard}>
          <div className={classes.cardContentWrapper}>
            {(tenantProfile?.pointConfiguration?.isEnabled ||
              tenantProfile?.walletConfig?.isEnabled) && (
              <CardContent className={classes.menuWalletVoucherWrapper}>
                {tenantProfile?.pointConfiguration?.isEnabled &&
                  tenantProfile?.walletConfig?.isEnabled && (
                    <div
                      className={classes.walletWrapper}
                      onClick={() => Router.push("/dafam-pay?page=dafampay")}
                    >
                      <img
                        src="/img/wallet.svg"
                        alt="wallet-icon"
                        className={classes.walletIcon}
                      />
                      <div>
                        <Typography className={classes.walletAmount}>
                          Rp{numberSeparator(wallet)}
                        </Typography>
                        <Typography className={classes.totalPoint}>
                          {numberSeparator(points)} {t("points")}
                        </Typography>
                      </div>
                    </div>
                  )}
                {tenantProfile?.pointConfiguration?.isEnabled &&
                  !tenantProfile?.walletConfig?.isEnabled && (
                    <div
                      className={classes.walletWrapper}
                      onClick={() => Router.push(`/dafam-pay?page=point`)}
                    >
                      <img
                        src="/img/point-icon.svg"
                        alt="point-icon"
                        height={13}
                        width={18}
                        className={classes.walletIcon}
                      />
                      <div>
                        <Typography className={classes.myPoints}>
                          {t("my-points")}
                        </Typography>
                        <Typography>{numberSeparator(points)}</Typography>
                      </div>
                    </div>
                  )}
                {!tenantProfile?.pointConfiguration?.isEnabled &&
                  tenantProfile?.walletConfig?.isEnabled && (
                    <div
                      className={classes.walletWrapper}
                      onClick={() => Router.push(`/dafam-pay?page=dafampay`)}
                    >
                      <img
                        src="/img/wallet.svg"
                        alt="point-icon"
                        height={13}
                        width={18}
                        className={classes.walletIcon}
                      />
                      <div>
                        <Typography className={classes.myPoints}>
                          {t("my-balance")}
                        </Typography>
                        <Typography>Rp{numberSeparator(wallet)}</Typography>
                      </div>
                    </div>
                  )}
              </CardContent>
            )}
            <CardContent className={classes.menuWalletVoucherWrapper}>
              <div
                className={classes.walletWrapper}
                onClick={() => Router.push("/vouchers")}
              >
                <div className={classes.walletIcon}>
                  <VoucherIcon />
                </div>
                {!tenantProfile?.pointConfiguration?.isEnabled &&
                !tenantProfile?.walletConfig?.isEnabled ? (
                  <div>
                    <Typography className={classes.voucher}>Voucher</Typography>
                    <Typography
                      variant="caption"
                      className={classes.totalVoucher}
                    >
                      {totalVoucher}{" "}
                      {i18n.language === "en"
                        ? totalVoucher > 1
                          ? "Vouchers"
                          : "Voucher"
                        : "Voucher"}
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <Typography
                      style={{
                        color:
                          tenantProfile?.pointConfiguration?.isEnabled &&
                          tenantProfile?.walletConfig?.isEnabled
                            ? "#25282B"
                            : "#595959",
                        fontWeight:
                          tenantProfile?.pointConfiguration?.isEnabled &&
                          tenantProfile?.walletConfig?.isEnabled
                            ? 700
                            : 400,
                        fontSize:
                          tenantProfile?.pointConfiguration?.isEnabled &&
                          tenantProfile?.walletConfig?.isEnabled
                            ? 14
                            : 12,

                        marginBottom: 1,
                      }}
                    >
                      Voucher
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{
                        color:
                          tenantProfile?.pointConfiguration?.isEnabled &&
                          tenantProfile?.walletConfig?.isEnabled
                            ? "#A8ABAD"
                            : "#25282B",
                        fontWeight:
                          tenantProfile?.pointConfiguration?.isEnabled &&
                          tenantProfile?.walletConfig?.isEnabled
                            ? 400
                            : 700,
                        fontSize:
                          tenantProfile?.pointConfiguration?.isEnabled &&
                          tenantProfile?.walletConfig?.isEnabled
                            ? 12
                            : 14,
                      }}
                    >
                      {totalVoucher}{" "}
                      {i18n.language === "en"
                        ? totalVoucher > 1
                          ? "Vouchers"
                          : "Voucher"
                        : "Voucher"}
                    </Typography>
                  </div>
                )}
              </div>
            </CardContent>
          </div>
          {tenantProfile?.membershipConfig?.isEnabled && (
            <div className={classes.membershipWrapper}>
              <div className={classes.groupWrapper}>
                <Typography className={classes.groupTypography}>
                  {membershipData?.level?.name}
                  {(membershipData?.level?.level as number) + 1 <
                  (membershipData?.group?.levels.length as number) ? (
                    <span className={classes.expTypography}>
                      {getLevelDifference(
                        membershipData as GuestMembershipByGroupIdDto["data"]
                      )}{" "}
                      {t("to")}{" "}
                      {
                        membershipData?.group.levels[
                          membershipData.level.level + 1
                        ].name
                      }
                    </span>
                  ) : (
                    <span className={classes.expTypography}>
                      {t("youve-reached-maximum-level")}
                    </span>
                  )}
                </Typography>
                {(membershipData?.level?.level as number) + 1 <
                (membershipData?.group?.levels.length as number) ? (
                  <LinearProgress
                    color="primary"
                    value={getLevelProgress(
                      membershipData as GuestMembershipByGroupIdDto["data"]
                    )}
                    variant="determinate"
                    classes={{
                      root: classes.progressRoot,
                    }}
                  />
                ) : (
                  <LinearProgress
                    color="primary"
                    value={100}
                    variant="determinate"
                    classes={{
                      root: classes.progressRoot,
                    }}
                  />
                )}
              </div>
              <Link href="/membership">
                <ChevronRightIcon
                  classes={{
                    root: classes.chevronRight,
                  }}
                />
              </Link>
            </div>
          )}
        </Card>
      ) : (
        <Link href="/login">
          <Card
            className={classes.rootCard}
            onClick={() => localStorage.setItem("from", "home")}
          >
            <CardContent className={classes.becomeMemberCard}>
              <div>
                <Typography className={classes.becomeMemberTitle}>
                  {t("become-member")}
                </Typography>
                <Typography className={classes.becomeMemberSubtitle}>
                  {t("get-a-lot-rewards-by-joining")}
                </Typography>
              </div>
              <ChevronRightIcon
                classes={{
                  root: classes.chevronRight,
                }}
              />
            </CardContent>
          </Card>
        </Link>
      )}
    </div>
  );
};
export default CardMembership;
