const HomeIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2641 0.442242L17.2227 5.89459C17.6979 6.27369 17.9789 6.84198 18 7.45218L17.9991 19.0099C17.9991 19.5568 17.5459 19.9993 16.9837 19.9993H11.8821C11.868 19.9998 11.8539 20 11.8396 20H6.16035C6.14616 20 6.13202 19.9998 6.11795 19.9993H1.01543C0.454125 19.9993 0 19.5577 0 19.0099V7.45218C0 6.84198 0.281084 6.27371 0.756299 5.89459L7.69308 0.442242C8.44929 -0.147414 9.52972 -0.147414 10.2641 0.442242ZM13 18.0216V14.1228C13 13.5021 12.482 13 11.8396 13H6.16035C5.51795 13 5 13.5022 5 14.1228V18.0216H2.00964V7.45231V7.43093L8.98942 1.97858H9.01136L15.9691 7.43093V7.45231L15.9692 18.0216H13ZM7.32061 18H10.6793V15.2455H7.32061V18Z"
        fill="#A0A4A8"
      />
    </svg>
  );
};

export default HomeIcon;
