import { axiosInstance } from "../config/axios";
import {
  DetailVoucherDto,
  GuestVoucherDataDto,
  ListGuestVoucherDto,
  GuestVoucherParamsDto,
} from "../libs/voucher";

export const getGuestVoucher = async ({
  page,
  per_page,
  search,
}: GuestVoucherParamsDto): Promise<ListGuestVoucherDto> => {
  const response: ListGuestVoucherDto = await axiosInstance.get(
    "/guest/vouchers/owned",
    {
      params: {
        page,
        per_page,
        search,
      },
    }
  );
  return response;
};

export const getVoucherById = async (id: string): Promise<DetailVoucherDto> => {
  const response: DetailVoucherDto = await axiosInstance.get(
    `/guest/vouchers/${id}/detail`
  );
  return response;
};

export const getListAllVoucher = async ({
  page,
  per_page,
  search,
}: GuestVoucherParamsDto): Promise<ListGuestVoucherDto> => {
  const response: ListGuestVoucherDto = await axiosInstance.get(
    "/guest/vouchers",
    {
      params: {
        page,
        per_page,
        search,
      },
    }
  );
  return response;
};

export const buyVoucher = async (id: string): Promise<GuestVoucherDataDto> => {
  const response: GuestVoucherDataDto = await axiosInstance.post(
    `/guest/vouchers/${id}/buy`
  );
  return response;
};
