const VoucherIcon = () => {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="13" rx="2" fill="#2F9CF1" />
      <ellipse
        cx="4.19909"
        cy="7.97727"
        rx="1.36364"
        ry="1.47727"
        fill="white"
      />
      <path
        d="M7.2002 11.8157H1.2002C1.2002 10.8366 2.54334 10.043 4.2002 10.043C5.85705 10.043 7.2002 10.8366 7.2002 11.8157Z"
        fill="white"
      />
    </svg>
  );
};

export default VoucherIcon;
