import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { TFunction } from "next-i18next";
import React, { useEffect } from "react";
import { Router } from "../../i18n";
import HelpIcon from "../../public/img/HelpIcon";
import HelpIconActive from "../../public/img/HelpIconActive";
import HomeIcon from "../../public/img/HomeIcon";
import HomeIconActive from "../../public/img/HomeIconActive";
import OrderIcon from "../../public/img/OrderIcon";
import OrderIconActive from "../../public/img/OrderIconActive";
import ProfileIconActive from "../../public/img/ProfileIconActive";

type Props = {
  readonly t: TFunction;
};

const BottomNav = (props: Props) => {
  const [value, setValue] = React.useState<number | null>(null);
  const classes = useStyles(props);
  const { t } = props;

  const path = Router.pathname;

  useEffect(() => {
    if (path === "/") {
      setValue(0);
    } else if (path === "/orders") {
      setValue(1);
    } else if (path === "/help") {
      setValue(2);
    } else if (path === "/profile") {
      setValue(3);
    }
  }, []);

  const handleClickProfile = () => {
    if (localStorage.getItem("token")) {
      Router.push("/profile");
    } else {
      Router.push("/login?redirect=/profile");
    }
  };

  const handleClickMyOrder = () => {
    if (localStorage.getItem("token")) {
      Router.push("/orders");
    } else {
      Router.push("/login?redirect=/orders");
    }
  };

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.stickyToBottom}
    >
      <BottomNavigationAction
        onClick={() => Router.push("/")}
        className={classes.btnAction}
        label="Home"
        classes={{
          selected: classes.selectedMenu,
          label: classes.label,
        }}
        icon={value === 0 ? <HomeIconActive /> : <HomeIcon />}
      />
      <BottomNavigationAction
        onClick={handleClickMyOrder}
        className={classes.btnAction}
        label={t("my-order-bottom-nav")}
        classes={{
          selected: classes.selectedMenu,
          label: classes.label,
        }}
        icon={value === 1 ? <OrderIconActive /> : <OrderIcon />}
      />
      <BottomNavigationAction
        onClick={() => Router.push("/help")}
        className={classes.btnAction}
        label={t("help-bottom-nav")}
        classes={{
          selected: classes.selectedMenu,
          label: classes.label,
        }}
        icon={value === 2 ? <HelpIconActive /> : <HelpIcon />}
      />
      <BottomNavigationAction
        onClick={handleClickProfile}
        className={classes.btnAction}
        label={t("profile-bottom-nav")}
        icon={
          value === 3 ? <ProfileIconActive /> : <AccountCircleOutlinedIcon />
        }
        classes={{
          selected: classes.selectedMenu,
          label: classes.label,
        }}
      />
    </BottomNavigation>
  );
};

export default BottomNav;

const useStyles = makeStyles(() =>
  createStyles({
    stickyToBottom: {
      width: "100%",
      maxWidth: 444,
      position: "fixed",
      bottom: 0,
      boxShadow: "0px -4px 10px -10px rgba(0,0,0,0.59)",
      maxHeight: "60px",
    },
    btnAction: {
      minWidth: 10,
    },
    label: {
      width: "max-content",
      color: "#0000008a",
      marginTop: "4px",
      fontSize: "12px !important",
      fontWeight: 700,
    },
    selectedMenu: {
      color: "#25282B !important",
    },
  })
);
