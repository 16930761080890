const HomeIconActive = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2227 5.89459L10.2641 0.442242C9.52972 -0.147414 8.44929 -0.147414 7.69308 0.442242L0.756299 5.89459C0.281084 6.27371 0 6.84198 0 7.45218V19.0099C0 19.5577 0.454125 19.9993 1.01543 19.9993H3V20H6.16035H11.8396H15V19.9993H16.9837C17.5459 19.9993 17.9991 19.5568 17.9991 19.0099L18 7.45218C17.9789 6.84198 17.6979 6.27369 17.2227 5.89459ZM13 18V14.1228C13 13.5021 12.482 13 11.8396 13H6.16035C5.51795 13 5 13.5022 5 14.1228V18H7.32061V15.2455H10.6793V18H13Z"
        fill="#25282B"
      />
    </svg>
  );
};

export default HomeIconActive;
