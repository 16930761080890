const ProfileIconActive = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0197 16.1051C14.3242 15.4392 12.5182 15.0843 10.6895 15.0618C8.86177 15.0988 7.05842 15.4556 5.35991 16.1123C6.78443 17.3546 8.6472 18.107 10.6857 18.107C12.7281 18.107 14.5941 17.3516 16.0197 16.1051ZM0.685669 9.99999C0.685669 4.47726 5.16292 0 10.6857 0C16.2084 0 20.6856 4.47726 20.6856 9.99999C20.6856 15.5227 16.2084 20 10.6857 20C5.16292 20 0.685669 15.5227 0.685669 9.99999ZM6.44702 7.53087C6.44702 5.19019 8.34502 3.29219 10.6857 3.29219C13.0264 3.29219 14.9244 5.19019 14.9244 7.53087C14.9244 9.87155 13.0264 11.7695 10.6857 11.7695C8.34502 11.7695 6.44702 9.87155 6.44702 7.53087Z"
        fill="#2C2C2C"
      />
    </svg>
  );
};

export default ProfileIconActive;
